export default function RLine() {
    return (
        <div className="rline">
            <svg width="705" height="617" viewBox="0 0 705 617" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="470" y="-323" width="1" height="940" fill="url(#paint0_linear_3571_1215)"/>
                <rect x="940.5" y="146.5" width="1" height="940" transform="rotate(90 940.5 146.5)"
                      fill="url(#paint1_linear_3571_1215)"/>
                <defs>
                    <linearGradient id="paint0_linear_3571_1215" x1="470.5" y1="-323" x2="470.5" y2="617"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_3571_1215" x1="941" y1="146.5" x2="941" y2="1086.5"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-opacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}