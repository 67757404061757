export default function LLine() {
    return (
        <div className="lline">
            <svg width="572" height="939" viewBox="0 0 572 939" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="57" y="-1" width="1" height="940" fill="url(#paint0_linear_3571_1216)"/>
                <rect x="-368" y="539" width="1" height="940" transform="rotate(-90 -368 539)"
                      fill="url(#paint1_linear_3571_1216)"/>
                <defs>
                    <linearGradient id="paint0_linear_3571_1216" x1="597.5" y1="-1" x2="97.5" y2="939"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_3571_1216" x1="-367.5" y1="539" x2="-367.5" y2="1479"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-opacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}